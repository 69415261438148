(function () {
  'use strict';

  class InvitationCtrl {
    constructor($stateParams, User, $state) {
      let vm = this;
      vm.ctrlName = 'InvitationCtrl';
      vm.$stateParams = $stateParams;
      vm.User = User;
      vm.$state = $state;
      vm.init();
    }
    init() {
      const vm = this;
      vm.User.getStudents(vm.$stateParams)
        .then((data)=> {
          vm.user = data.user;
        });
    }
    signup(form) {
      const vm = this;
      if (form.$valid) {
        vm.User.setUserCookie({user: vm.user});
        vm.User.userEdit(vm.user, true)
          .then((data)=>{
            if (data.user.role === 'admin') {
              return vm.$state.go('dashboard.learning.index');
            }
            vm.$state.go('dashboard.index');
          })
          .catch((data)=>{
            vm.User.logout();
            if (data.status === 403) {
              vm.error = data;
            }
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name invitation.controller:InvitationCtrl
   *
   * @description
   *
   */
  angular
    .module('invitation')
    .controller('InvitationCtrl', InvitationCtrl);
}());
